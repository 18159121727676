@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  height: 100vh;
}
@layer base {
  @font-face {
    font-family: "rocheSans";
    src: url("../public/fonts/RocheSans-Regular.ttf");
  }
  @font-face {
    font-family: "rocheSerif";
    src: url("../public/fonts/RocheSerif-Regular.ttf");
  }
}
@layer components {
  .form__element {
    @apply border-solid border border-slate-800  rounded-md p-2 disabled:bg-gray-100 disabled:border-gray-400 disabled:text-gray-300;
  }
  .form_button {
    @apply text-white font-semibold h-12 flex justify-center align-middle;
  }
  .auth-logo {
    @apply h-10 rounded-full;
  }
  .button {
    @apply bg-rocheButtonBlue border-rocheButtonBlue form_button;
  }
  .roundedButton {
    @apply button rounded-3xl;
  }
  .reset_button {
    @apply bg-red-700 border-red-700 enabled:hover:bg-red-600 form_button;
  }
  .submit_button {
    @apply bg-rocheGreen border-rocheGreen enabled:hover:bg-green-600 form_button;
  }

  .submit_button_black {
    @apply submit_button bg-black enabled:hover:bg-gray-600 border-black enabled:hover:border-gray-600
  }
  .form {
    @apply shadow-2xl min-w-min mx-auto rounded-md w-2/3 px-8 bg-orange-50;
  }
  .auth {
    @apply button absolute top-6 right-6;
  }
  .question-border{
    @apply border-y border-y-2 border-t-gray-300 border-b-gray-300;
  }
  .section-info {
    @apply bg-rocheBlue text-white
  }
  h1 {
    @apply text-2xl font-semibold p-0 font-rocheSans;
  }
  h2 {
    @apply py-2 font-semibold text-lg font-rocheSans;
  }
  label {
    @apply hover:drop-shadow-md;
  }
  input,
  select {
    @apply form__element w-72;
  }
  textarea {
    @apply w-full h-[500px]  bg-transparent-0;
  }
  button {
    @apply form__element w-32 hover:drop-shadow-md enabled:hover:bg-blue-700 text-xl disabled:opacity-50;
  }
  a[target="_blank"] {
    @apply text-rocheBlue;
  }
  a[target="_blank"][type='button'] {
    @apply text-white p-3;
  }
  .full-height {
    @apply h-[calc(100vh_-_96px)];
  }
  .checkbox {
    @apply h-4 w-4 checked:bg-blue-500;
  }
  .identifierInput {
    @apply border-b rounded-none border-x-0 border-t-0 bg-transparent-0 max-w-max bg-transparent-700 rounded-t-md;
  }
  .guidance {
    @apply bg-white  py-2 px-4 question-border  h-[600px] overflow-auto font-rocheSans overflow-scroll
    bg-transparent-0 overflow-x-hidden scrollbar-track-transparent-0 scrollbar-thumb-gray-500 scrollbar-thin
  }
}
